import React, {Component, createRef} from 'react';
import BaseLayout from '../components/Layout/BaseLayout';
import {Link} from 'gatsby';
import {graphql} from 'gatsby';
import Scrollspy from 'react-scrollspy';

import {db} from "./../graphql/client.js";

import {gql} from 'apollo-boost';

import Dropdown from "react-bootstrap/Dropdown";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import {DISEASES_URL} from "../constants";
import ContentLoader from "react-content-loader";
import ResponsiveContainer from "../components/ResponsiveContainer";
import {Modal} from "react-bootstrap";

let refs = {};
let alphabet = [];

const Placeholders = ({isMobile}) => {

    return(
        <div>
            <div className="all-symptoms__alphabetic_section row">
                <div className="col-lg-12 col-sm-12"/>
            </div>
            { !isMobile && <ContentLoader
                speed={1}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                viewBox="0 0 800 800"
            >
                <rect x="0" y="0" rx="0" ry="0" width="33%" height="180" />
                <rect x="270" y="0" rx="0" ry="0" width="33%" height="180" />
                <rect x="540" y="0" rx="0" ry="0" width="33%" height="180" />

                <rect x="0" y="190" rx="0" ry="0" width="33%" height="180" />
                <rect x="270" y="190" rx="0" ry="0" width="33%" height="180" />
                <rect x="540" y="190" rx="0" ry="0" width="33%" height="180" />

                <rect x="0" y="380" rx="0" ry="0" width="33%" height="180" />
                <rect x="270" y="380" rx="0" ry="0" width="33%" height="180" />
                <rect x="540" y="380" rx="0" ry="0" width="33%" height="180" />

                <rect x="0" y="570" rx="0" ry="0" width="33%" height="180" />
                <rect x="270" y="570" rx="0" ry="0" width="33%" height="180" />
                <rect x="540" y="570" rx="0" ry="0" width="33%" height="180" />
            </ContentLoader> }

            { isMobile && <ContentLoader
                height={1400}
                width={800}
                speed={1}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
            >
                <rect x="0" y="0" rx="0" ry="0" width="100%" height="350" />
                <rect x="0" y="370" rx="0" ry="0" width="100%" height="350" />
                <rect x="0" y="740" rx="0" ry="0" width="100%" height="350" />
            </ContentLoader> }
        </div>
    )

}

const RenderAlphabeticalMenu = ({ handleClick, alphabet, list, isMobile }) => {

    return (
        (!isMobile) ?
        <Scrollspy items={alphabet} currentClassName="active" offset={-150}>
            {
                alphabet.map((letter, i) => {
                    if (list[letter.toUpperCase()]) {
                        return <li key={i}><span onClick={() => handleClick(i)}>{letter.toUpperCase()}</span></li>
                    }
                    return <li key={i}><span className='not-available'>{letter.toUpperCase()}</span></li>
                })
            }
        </Scrollspy>
        :
        <Dropdown className="overview-dropdown">
            <Dropdown.Toggle id="dropdown-item-button" >
                {'Inhoudsopgave A-Z '}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {
                    alphabet.map((letter, i) => {
                        if (list[letter.toUpperCase()]) {
                            return   <Dropdown.Item
                                key={i}
                                as="button"
                                onClick={() => handleClick(i)}
                                value={letter.toUpperCase()}
                                className={'active'}
                            >
                                {letter.toUpperCase()}
                            </Dropdown.Item>
                        }
                        return <Dropdown.Item
                            key={i}
                            as="button"
                            onClick={() => handleClick(i)}
                            value={letter.toUpperCase()}
                            disabled={true}
                            className='not-available'
                        >
                            {letter.toUpperCase()}
                        </Dropdown.Item>
                    })
                }
            </Dropdown.Menu>
        </Dropdown>

    )
};

class Symptoms extends Component {

    constructor(props) {

        super(props);

        this.state = {
            show: false,
            symptoms: [],
            filteredSymptoms: [],
            error: false,
            errorMessage: "",
            categories: [],
            selectedCategory: 0,
            selected: false,
            showModal:false,
            symptom:null,
            extendItems:null
        };

        alphabet = [...Array(26).keys()].map(i => String.fromCharCode(i + 97));

        // create ref for scrolling on side-bar-menu click
        refs = alphabet.reduce((acc, value, x) => {
            acc[x] = createRef();
            return acc;
        }, {});


    }

    componentDidMount() {

        // DB query
        db.query({
            query: gql`
        {
            
            categories {
                name
                id
            }
            
            allSymptoms {
                name
                description
                category
                synonymous
                id
                diseases {
                    id
                    name
                    uri
                }
            }
        }
      `
        })
        .then((response) => {

            let symptoms = response.data.allSymptoms;
            let categories = response.data.categories.map((category) => {
                return {
                    id:category.id,
                    name:category.name
                };
            });
            categories.push({
                id: 0,
                name: 'Allemaal'
            });
            categories.splice(0, 0, categories.pop());
            this.setState({ symptoms, categories, filteredSymptoms: symptoms });

        })
        .catch(error => {

            this.setState({
                error: true,
                errorMessage: "Can't fetch data [symptoms]"
            });
        });

        this.setState({
            show: true
        });
    }

    // handle filter change
    handleChange = (e) => {

        if (e.target.value == 0) {
            let filteredSymptoms = JSON.parse(JSON.stringify(this.state.symptoms));
            this.setState({ filteredSymptoms, selectedCategory: 0, selected: true });
        } else {
            let filteredSymptoms = JSON.parse(JSON.stringify(this.state.symptoms)).filter(
                symptom => symptom.category === e.target.value
            );
            this.setState({ filteredSymptoms, selectedCategory: e.target.value, selected: true });
        }

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

    };

    handleModal(e, item, items){

        e.preventDefault();

        this.setState({
            showModal:true,
            symptom:item,
            extendItems: items
        });

    };

    render() {


        const { data, location, isMobile } = this.props;

        const {filteredSymptoms, selected, showModal, symptom, extendItems} = this.state;

        // detect support for the behavior property in ScrollOptions
        const supportsNativeSmoothScroll = (typeof document !== 'undefined') ? 'scrollBehavior' in document.documentElement.style : null;

        // create an array with all diseases / alphabetic check
        let list = filteredSymptoms
            .sort((a, b) => a.name.localeCompare(b.name))
            .reduce((r, e) => {

                const key = e.name[0];

                if (!r[key]) r[key] = []
                r[key].push(e);
                return r;

            }, {});

        // scroll to the section on alphabet-bar-menu click
        // @todo: function in render?
        const handleClick = id => {
            if (refs[id].current) {

                if (typeof window !== 'undefined') {
                    const el = document.getElementById(refs[id].current.id);
                    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;

                    window.scrollTo({
                        top: yCoordinate - 180,
                        behavior: 'smooth'
                    });

                    // IE SUPPORT FOR LACK OF SMOOTH SCROLL
                    if(!supportsNativeSmoothScroll) {
                        refs[id].current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                        });
                    }

                    return;

                }
            }
        };

        let items = [];

        return (
            <BaseLayout show={this.state.show} data={data.gravql} location={location} crumbLabel={data.gravql.page.title}  showSubNav={true}>
                {symptom && <Modal
                    centered
                    show={showModal}
                    onHide={()=>{
                        this.setState({showModal:false});
                    }}
                    dialogClassName="modal-90w"
                    aria-labelledby="symptom-diseases-modal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="symptom-diseases-modal">
                            <h6>Stapelingsziekten</h6>
                            {symptom.name}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ul>
                        {extendItems && extendItems.map((item, o) => {
                            if(o>5) items.push(item);
                            // return (o <= 5) ? (<li key={o}><Link to={DISEASES_URL + item.uri}>{item.name}</Link></li>) : null
                            return <li key={o}><Link to={DISEASES_URL + item.uri}>{item.name}</Link></li>
                        }) }
                        </ul>
                    </Modal.Body>
                </Modal> }
                <section className="all-symptoms">
                    <h1>{this.props.data.gravql.page.title}</h1>
                    <div className="sticky-menu">
                        <ResponsiveContainer>
                            <RenderAlphabeticalMenu handleClick={handleClick} alphabet={alphabet} list={list} />
                        </ResponsiveContainer>
                        {/* SELECT FILTER */}
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-item-button" >
                                {this.state.categories.length > 0 && (selected) ? this.state.categories[this.state.selectedCategory].name : 'Therapeutisch gebied'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {this.state.categories.length > 0 && this.state.categories.map((category) => {
                                    return (
                                        <Dropdown.Item
                                            key={category.id}
                                            as="button"
                                            onClick={this.handleChange}
                                            value={category.id}
                                            className={ this.state.selectedCategory === category.id ? 'active' : ''}
                                        >
                                            {category.name}
                                        </Dropdown.Item>
                                    )
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    {this.state.symptoms.length == 0 &&
                        <ResponsiveContainer>
                            <Placeholders/>
                        </ResponsiveContainer>
                    }

                    {/* RENDER SYMPTOMS */}
                    {this.state.symptoms.length > 0 && Object.entries(list)
                        .map(([key, value], i) => {

                            let letter = alphabet.indexOf(key.toLowerCase());

                            return (
                            <div key={key}>
                                <Row className="all-symptoms__alphabetic_section">
                                    <Col sm={12} lg={12}>
                                        <div ref={refs[letter]} id={key.toLowerCase()}/>
                                        <strong id={key.toLowerCase()}>{key}</strong>
                                    </Col>
                                </Row>
                                <Row key={i}>
                                    {value.map((item, j) => {
                                        let items=[];
                                        return (
                                            <Col key={j} sm={12} lg={4} >
                                                <a className="symptom" key={i} onClick={(e)=>this.handleModal(e, item, item.diseases)}>
                                                    <span name={item.name} />
                                                    <h5>{item.name}</h5>
                                                    <p>{item.synonymous}</p>
                                                    <div className="slide">
                                                    </div>
                                                </a>
                                            </Col>)
                                    })}
                                </Row>
                            </div>
                            )
                        })}
                </section>
            </BaseLayout>
        )
    }
}

export default Symptoms;

export const query = graphql`
  query ($slug:String!) {
    gravql {
        pages {
            title
            route
            visible
            header {
                title
                body_classes
            }
            children {
                title
                route
                header {
                    title
                    body_classes
                }
                 children {
                    title
                    route
                     header {
                        title
                        body_classes
                    }
                }
            }
        }
        page(route: $slug) {
            title      
            content
            visible
            route
            breadcrumbs
            header {
                body_classes
            }
            children {
                title
                route  
                id
                content  
                template 
                header {
                    body_classes
                    title
                }
                 children {
                    title
                    route
                    content
                    header {
                        title
                        body_classes
                    }
                }         
            }        
            metadata {
                name
                content
            }   
        }
    }
  }
`;
